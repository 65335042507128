import _omitBy from 'lodash/fp/omitBy'
import _isNil from 'lodash/fp/isNil'
import request from './request'
import {onError} from './pureFetcher'


export default {
  get: (url, params, data) =>
    request({
      url,
      params: _omitBy(_isNil,params),
      data,
      method: 'GET'
    }).catch(onError),

  post: (url, params, data) =>
    request({
      url,
      params,
      data,
      method: 'POST'
    }).catch(onError),

  put: (url, params, data) =>
    request({
      url,
      params,
      data,
      method: 'PUT'
    }).catch(onError),
  patch: (url, params, data) =>
    request({
      url,
      params,
      data,
      method: 'PATCH'
    }).catch(onError),
  delete: (url, params, data) =>
    request({
      url,
      params,
      data,
      method: 'DELETE'
    }).catch(onError)
}
