import React from 'react';
import AntdSelect from "antd/es/select";
import {CloseCircleFilled} from '@ant-design/icons';
import "./Select.less";

// if dont wrap inside component, when use form component + getFieldDecorator will be show a messege:
//antd form Function components cannot be given refs
export default class Select extends React.Component {
  render() {
    return <AntdSelect {...this.props} />;
  }
}


Select.defaultProps = {
  removeIcon: (<CloseCircleFilled />)
}