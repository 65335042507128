import React from 'react'
import PropTypes from 'prop-types'
import { AntdDatePickerMod } from './DatePicker'
import moment from 'moment'
import {injectIntl} from 'react-intl'
import messages from './defined.messages'

const { RangePicker } = AntdDatePickerMod

const DEFAULT_PRESET = {
  today: [moment(), moment()],
  last7day: [moment().subtract(7, 'd'), moment()],
  last30day: [moment().subtract(30, 'd'), moment()]
}

// eslint-disable-next-line no-unused-vars
const DateRangePicker = ({ value, placeholder, intl, ...props }) => {
  const RANGES = {
    [intl.formatMessage({ ...messages.today })]: DEFAULT_PRESET.today,
    [intl.formatMessage({ ...messages.lastTimeDay }, { day: `7` })]: DEFAULT_PRESET.last7day,
    [intl.formatMessage({ ...messages.lastTimeDay }, { day: `30` })]: DEFAULT_PRESET.last30day,
  }
  return <RangePicker value={value && value.length ? value : []} ranges={props.ranges || RANGES} {...props} />
}

DateRangePicker.propTypes = {
  value: PropTypes.array,
  placeholder: PropTypes.array
}

DateRangePicker.defaultProps = {
  style: { width: '100%' }
}

export default injectIntl(DateRangePicker)
