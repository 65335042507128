import React from 'react'
import {Helmet} from 'react-helmet'
import {useSelector} from 'react-redux'
import {stateManager} from './store'


const DocumentHead = () => {
  const pageTitle = useSelector(stateManager.getLayoutConfig__pageTitle)
  return <Helmet
    title= {pageTitle}
    defaultTitle="VAROOM"
  >
    <meta name="description" content="VAROOM application"/>
  </Helmet>
}

DocumentHead.propTypes = {}

export default DocumentHead




