import MyOkrsDetailsLayout from "./DetailsLayout.Loadable"
import {FEATURE_KEY} from "../constants"
const ROUTES = [{
  path: `/my-okrs/:id`,
  exact: true,
  component: MyOkrsDetailsLayout,
  layoutConfig: {
    isUsedAppShell: true,
    title: 'MyOkrs Details',
    routes: [
      {
        breadcrumbName: 'MyOkrs Details'
      }
    ]
  },
  featureKey: FEATURE_KEY
}]

export default ROUTES
