import React, {memo, useMemo} from "react";
import classNames from "classnames";
import {injectIntl} from "react-intl";
import {Link} from "react-router-dom";
import styles from "./MainMenuItem.module.scss";

const MainMenuItem = (props) => {
  const {intl, menuItem: {labelId, path = "", icon: IconComp}} = props
  const menuName = useMemo(() => intl.formatMessage({id: labelId}), [intl, labelId])
  const Tag = path ? Link : "span";

  return <div className={classNames(styles.varComp, styles.varComp__inline)}>
    {IconComp ? <IconComp/> : null}
    <Tag className={styles.varComp__text } to={path}>{menuName}</Tag>
  </div>
};

MainMenuItem.propTypes = {}
export default injectIntl(memo(MainMenuItem));