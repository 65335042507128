import React from "react"
import PropTypes from 'prop-types'
const VaroomImg = ({onError, alt, src, ...props}) => (
  // eslint-disable-next-line react/react-in-jsx-scope
  <img
    {...props}
    alt={alt || 'image'}
    src={src && src.length > 2 ? src : ''}
  />
)

VaroomImg.propTypes = {
  onError: PropTypes.any,
  alt: PropTypes.any,
  src: PropTypes.any
}

export default VaroomImg
