import _pipe from "lodash/fp/pipe"
import moment from "moment"


export const toDate = date => moment.utc(date || null)
export const isValidDate = date => toDate(date).isValid()

export const compareDate = (date1, date2, bias) => {
  const d1 = toDate(date1)
  const d2 = toDate(date2)

  if (bias) {
    d1.add(bias)
  }

  return d1.isAfter(d2)
  // return d1.valueOf() > d2.valueOf()
}

export const dateFormatter = (date, format = "MMM D, YYYY") =>
  toDate(date).local().format(format)

export const relativeFormatter = date => toDate(date).fromNow() || "N/A"

export const dateTimeFormatter = (date, format = "MMM D, YYYY - hh:mma") =>
  toDate(date).local().format(format)

export const apiDateFormatter = (date, format = "YYYY-MM-DD") =>
  moment(date).format(format)

export const dateShowRelative = _pipe([
  toDate,
  date => date.diff(undefined, "days")
    ? dateTimeFormatter(date)
    : relativeFormatter(date)
])

export const dateShowRelativeArr = _pipe([
  toDate,
  date => date.diff(undefined, "days")
    ? [dateTimeFormatter(date), false]
    : [relativeFormatter(date), true]
])

export const apiUtcFormatter = (date, format) => {
  if (format) {
    return moment.utc(date).format(format).slice(0, 19);
  } else {
    return moment.utc(date).format().slice(0, 19);
  }
}

export const toISOString = date => date.toISOString()

export function formatStartDateFilterStr(startDate) {
  console.log("startDate", startDate.format());
  const localHour = moment().get("h");
  const localMinute = moment().get("m");
  const localSecond = moment().get("s");
  return startDate.clone()
    .set("h", localHour)
    .set("m", localMinute)
    .set("s", localSecond)
    .utc().startOf("day").toISOString();
}

export function formatEndDateFilterStr(endDate) {
  const localHour = moment().get("h");
  const localMinute = moment().get("m");
  const localSecond = moment().get("s");
  return endDate.clone()
    .set("h", localHour)
    .set("m", localMinute)
    .set("s", localSecond)
    .utc().endOf("day").toISOString();
}
