import React from 'react'
import PropTypes from 'prop-types'
import TypographyAntd from 'antd/es/typography'
import './Typography.less'
import styles from "./index.module.scss"

const { Title: TitleAntd } = TypographyAntd
const Title = props => <TitleAntd {...props} />

const { Paragraph: ParagraphAntd } = TypographyAntd
const Paragraph = props => <ParagraphAntd {...props} />


const { Text: TextAntd } = TypographyAntd

const Text = ({primary, secondary, className, ...rest }) => {
  let styleClass = className
  if (primary) {
    styleClass = `${styleClass} ${styles.textPrimary}`.trim()
  } else if (secondary) {
    styleClass = `${styleClass} ${styles.textSecondary}`.trim()
  }

  return <TextAntd {...rest} className={styleClass} />
}
Text.defaultProps = {
  className: ''
}

Text.propTypes = {
  className: PropTypes.string,
  primary: PropTypes.bool,
  secondary: PropTypes.bool,
  type: PropTypes.string
}

export { Title, Text,Paragraph }
