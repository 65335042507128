import React from 'react'
import AntdSpin from 'antd/es/spin'
import {LoadingOutlined} from '@ant-design/icons';
import './Spin.less'

const Spin = props => <AntdSpin {...props} />

Spin.defaultProps = {
  indicator: <LoadingOutlined style={{ fontSize: 24 }} spin/>
}


export default Spin
