export const setUserToStorage = (userInfo) => {
  try {
    window.localStorage.setItem('varCurrentUser', JSON.stringify(userInfo))
  } catch (e) {
    console.log(e)
  }
}

export const getUserFromStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem('varCurrentUser'))
  } catch (e) {
    console.log(e)
    return null
  }
}

export const removeUserFromStorage = () => {
  try {
    return window.localStorage.clear();
  } catch (e) {
    console.log(e)
    return null
  }
}

export const getAuthFromStorage = () => {
  try {
    return JSON.parse(window.localStorage.getItem('auth'))
  } catch (e) {
    console.log(e)
    return null
  }
}
