import React from "react"
import AntdSkeleton from "antd/es/skeleton";
import "./Skeleton.less";

const Skeleton = props => <AntdSkeleton {...props} />;

Skeleton.defaultProps = {
  active: true
}

export default Skeleton;
