import React from 'react';
import AntdDatePicker from "antd/es/date-picker";
import './DatePicker.less';

const DatePicker = (props) => <AntdDatePicker {...props}/>;

DatePicker.propTypes = {
  ...AntdDatePicker.propTypes
};

export default DatePicker;

export const AntdDatePickerMod = AntdDatePicker
