import * as types from "./types"

export function initData() {
  return {type: types.INIT_DATA}
}

export function resetData() {
  return {type: types.RESET_DATA}
}

export function getReminder() {
  return {type: types.GET_REMINDER}
}

export function getReminderSuccess(reminders) {
  return {type: types.GET_REMINDER_SUCCESS, payload: {reminders}}
}

export function getReminderError(error) {
  return {type: types.GET_REMINDER_ERROR, payload: {error}}
}

export function updateReminder(formData) {
  return {type: types.UPDATE_REMINDER, payload: {formData}}
}

export function updateReminderSuccess(reminders) {
  return {type: types.UPDATE_REMINDER_SUCCESS, payload: {reminders}}
}

export function updateReminderError(error) {
  return {type: types.UPDATE_REMINDER_ERROR, payload: {error}}
}
