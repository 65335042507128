import React from "react"
import PropTypes from "prop-types"
import parseLink from "utils/parseLink"

const Link = ({url, children, isOpenNewTab, ...rest}) => url ? (
  <a target={isOpenNewTab ? "_blank" : ""} {...rest} href={parseLink(url)}>{children || url}</a>) : null
Link.propTypes = {
  isOpenNewTab: PropTypes.any,
  children: PropTypes.any,
  url: PropTypes.string
}

Link.defaultProps = {
  isOpenNewTab: true
}

export default Link

