import {MODULE_STATE_NAME} from "./constants";

export const INIT_DATA = `${MODULE_STATE_NAME}/INIT-DATA`;
export const RESET_DATA = `${MODULE_STATE_NAME}/RESET-DATA`;
export const ADD_NEW_MESSAGE = `${MODULE_STATE_NAME}/ADD-NEW-MESSAGE`;
export const GET_MESSAGE_LIST = `${MODULE_STATE_NAME}/GET-MESSAGE-LIST`;
export const GET_MESSAGE_LIST_SUCCESS = `${MODULE_STATE_NAME}/GET-MESSAGE-LIST-SUCCESS`;
export const GET_MESSAGE_LIST_ERROR = `${MODULE_STATE_NAME}/GET-MESSAGE-LIST-ERROR`;
export const MARK_AS_READ = `${MODULE_STATE_NAME}/MARK-AS-READ`;
export const MARK_AS_READ_SUCCESS = `${MODULE_STATE_NAME}/MARK-AS-READ-SUCCESS`;
export const MARK_AS_READ_ALL = `${MODULE_STATE_NAME}/MARK-AS-READ-ALL`;
export const MARK_AS_READ_ALL_SUCCESS = `${MODULE_STATE_NAME}/MARK-AS-READ-ALL-SUCCESS`;
export const MARK_AS_READ_ALL_ERROR = `${MODULE_STATE_NAME}/MARK-AS-READ-ALL-ERROR`;
