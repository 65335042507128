import React from "react"
import {useFormContext} from "react-hook-form";
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import Skeleton from "components/ui/Skeleton";
import Form from "components/ui/Form";
import Button from "components/ui/Button";
import Space from "components/ui/Space";
import {getLoading} from "./reminder.domainData";
import {actions, stateManager} from "./store";
import EmailReminder from "./EmailReminer";
import styles from "./ReminderForm.module.scss"
import {formatFormData} from "./reminder.utils"

const ReminderForm = () => {
  const dispatch = useDispatch();
  const {
    control,
    setValue,
    handleSubmit,
    getValues,
    formState: {isDirty}
  } = useFormContext();
  const isLoading = getLoading(useSelector(stateManager.getUiConfig))
  const values = getValues();
  const handleCreate = (formData) => {
    formData = formatFormData(formData);
    dispatch(actions.updateReminder(formData))
  }

  if(isLoading) {
    return <Skeleton />
  }
  return <>
    <Form layout={"horizontal"} className={styles.varReminderForm}>
      <EmailReminder control={control} setValue={setValue} values={values} />
      <Space className="w-full justify-center">
        <Button onClick={handleSubmit(handleCreate)} disabled={!isDirty} className="btn btn-submit">Apply</Button>
      </Space>
    </Form>
  </>
}
ReminderForm.propTypes = {}
export default ReminderForm