import React, {useMemo, useState, useCallback} from "react"
import PropTypes from "prop-types"
import {dateTimeFormatter, relativeFormatter, dateShowRelativeArr} from "utils/dateFormatter"

import {Text} from "./Text"


const Time = ({time, pattern, showRelative, ...props}) => {
  const [, canShowedRelative] = useMemo(() => dateShowRelativeArr(time), [time])

  const relativeString = useMemo(() => relativeFormatter(time), [time])
  const fullString = useMemo(() => dateTimeFormatter(time, pattern), [time, pattern])

  const [internal_isShowRelative, set_internal_isShowRelative] = useState(canShowedRelative)
  const toggle = useCallback(() => set_internal_isShowRelative(!internal_isShowRelative), [internal_isShowRelative])

  return (<Text
    onClick={toggle}
    {...props}
  >{!time ? "-" : ((!showRelative || !internal_isShowRelative) ? fullString : relativeString)}</Text>)
}

Time.propTypes = {
  time: PropTypes.any,
  pattern: PropTypes.string,
  showRelative: PropTypes.bool
}

Time.defaultProps = {
  showRelative: true
}

export default Time



