import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.SET_GLOBAL_LOADING:
      return stateManager.updaterLoading(state, payload)
    case types.SET_NOTIFY:
      return stateManager.updaterNotifyInfo(state, payload)
    case types.SET_LAYOUT_CONFIG:
      return stateManager.updaterLayoutConfig(state, payload)
    case types.SET_LAYOUT_CONFIG_BREADCRUMB:
      return stateManager.updaterLayoutConfigBreadcrumb(state, payload)

    //   // Perform action
    //   return state
    // case types.GET_CURRENT_USER_ERROR:
    //   // Perform action
    //   return state
    case types.GET_CURRENT_USER:
    case types.UPDATE_CURRENT_USER_SUCCESS:
    case types.GET_CURRENT_USER_SUCCESS:
      return stateManager.updaterCurrentUser(state, payload)
    case types.UPDATE_CURRENT_USER:
      // Perform action
      return state
    case types.UPDATE_CURRENT_USER_ERROR:
      // Perform action
      return state
    case types.RESET_USER:
      return stateManager.updaterResetUser(state, payload)
    default: return state;
  }
}
