import {useMemo} from 'react'
import {useIntl, defineMessages as defineMessagesBase} from 'react-intl'
import _mapValues from 'lodash/fp/mapValues'
import _omit from 'lodash/fp/omit'

export const isDevelopMode = () => false

export const useTranslateContext = (featureName) => {
  const {formatMessage} = useIntl()
  return useMemo(() => ({
    formatMessage: (config, ...rest) => formatMessage(typeof config === 'string' ? {id: config} : config, ...rest),
    getFormattedId: (fieldName, type = 'label') => formatMessage({id: `${featureName}.${fieldName}.${type}`})
  }), [featureName, formatMessage])
}

export function getHtmlContentTranslation(key, content, params = {}) {
  try {
    let _content = content.replace(/</g, '<@@').replace(/'/g, 'Ω');
    const defaultMessage = { id: key, defaultMessage: _content};
    // eslint-disable-next-line react-hooks/rules-of-hooks
    const {formatMessage} = useIntl();
    let formattedContent = formatMessage(defaultMessage, params);
    formattedContent = formattedContent.replace(/<@@/g, '<').replace(/Ω/g, "'");
    return formattedContent;
  } catch (e) {
    console.log('getHtmlContentTranslation error: ', e)
    return content
  }
}


export const defineMessages = (messages) => {
  return defineMessagesBase(isDevelopMode() ? _mapValues(_omit('defaultMessage'))(messages) : messages)
}
