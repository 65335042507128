import PeopleLayout from "./PeopleLayout.Loadable"
import {FEATURE_KEY} from "./constants"

const ROUTES = [{
  path: `/people`,
  exact: true,
  component: PeopleLayout,
  layoutConfig: {
    isUsedAppShell: true,
    title: "People",
    routes: [
      {
        breadcrumbName: "People"
      }
    ]
  },
  featureKey: FEATURE_KEY
}]

export default ROUTES
