import React from "react"
import {Redirect, Route} from "react-router-dom"
import {useFeatureChecker} from "components/shared/PermissionManager";


const RouteWrapper = ({layoutConfig, featureKey, component: MainContent, ...props}) => {
  const {canUse} = useFeatureChecker(featureKey);
  if (!canUse) {
    return (<Redirect to="/login"/>)
  }
  return (<Route
    {...props}
    render={(args) => {
      return <MainContent {...args}/>
    }}
  />)
}


export default RouteWrapper
