import React from "react";
import Empty from "components/ui/Empty";

const NoNotificationMessages = () => (
  <div className={'dfoPad__base'} style={{height: 'auto'}}>
    <Empty description="No notification yet!"/>
  </div>
)

NoNotificationMessages.propTypes = {}
export default NoNotificationMessages