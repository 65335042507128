import React from "react";
import Layout, {Content} from "components/ui/Layout"
import LeftSide from "./LeftSide";
import {useInitData, injectToStoreNotification} from "components/shared/NotificationList";
import styles from "./AppShellLayout.module.scss";

const AppShellLayout = ({children}) => {
  const {pathname} = window.location;
  if(pathname !== "/no-permission") {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    useInitData();
  }
  return <Layout style={{minHeight: "100vh"}}>
    <LeftSide/>
    <Layout>
      <Content className={styles.varAppLayout}>
        <div>
          {children}
        </div>
      </Content>
    </Layout>
  </Layout>
}

AppShellLayout.propTypes = {}
export default injectToStoreNotification(AppShellLayout)