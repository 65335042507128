import React, {useCallback} from "react"
import _size from "lodash/fp/size";
import classNames from "classnames"
import List, {Item} from "components/ui/List";
import {Text, Time} from "components/ui/Typography"
import Badge from "components/ui/Badge";
import {getId, isRead, getDescription, getCreatedOn} from "./notificationMessage.domainData";
import injectToStore from "./store"
import {useMessageList, useMarkReadAction} from "./notificationHooks"
import NoNotificationMessages from "./NoNotificationMessages";
import NotificationMessageListHeader from "./NotificationMessageListHeader"
import styles from "./NotificationListLayout.module.scss";

const NotificationListLayout = () => {
  const list = useMessageList();
  const [markReadId, markAllRead] = useMarkReadAction();
  const markReadByIdCb = useCallback(({id}) => markReadId(id), [markReadId]);
  return <div className={`${styles.menu} ant-dropdown-menu`}>
    {!_size(list) ? <NoNotificationMessages /> :
    <>
      <NotificationMessageListHeader onMarkAll={markAllRead} />
      <List
        itemLayout="vertical"
        size="large"
        dataSource={list}
        renderItem={(item, index) => {
          const read = isRead(item)
          return (
            <Item className={classNames(styles.statusItem, {[styles.dfoCompRead]: !read})} key={index} onClick={() => markReadByIdCb({id: getId(item)})}>
              <Text strong><div dangerouslySetInnerHTML={{ __html: getDescription(item) }} /></Text><br/>
              <Time className={`ant-typography-time`} time={getCreatedOn(item)}/>
              {!read && <Badge status="processing"/>}
            </Item>
          )
        }}
      />
    </>}
  </div>
}

NotificationListLayout.propTypes = {}
export default injectToStore(NotificationListLayout)