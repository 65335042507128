import _get from "lodash/fp/get";
import _pipe from "lodash/fp/pipe";
import _over from "lodash/fp/over";
import _filter from "lodash/fp/filter";
import _size from "lodash/fp/size";
import _lt from "lodash/fp/lt";
import _join from "lodash/fp/join";

export const getRole = _get('role')
export const getUserId = _get('id')
export const getOwner = _get('owner')
export const getFeature = _get('feature')
export const getPermissions = _get('permissions')
export const getPermission = _get('permission')
export const getFirstName = _get('firstName')
export const getLastName = _get('lastName')
export const getFullName = _pipe([
  _over([_get('firstName'), _get('middleName'), _get('lastName')]),
  _filter(_pipe([_size, _lt(0)])),
  _join(' ')
])
export const getEmail = _get('email')
export const getImageURL = _get('imageURL')
export const getDepartment = _get('department')
export const getPosition = _get('position')
export const getSupervisorId = _get('supervisorId')
export const getChild = _get('children')

export const get_id = _get('id')
// export const getTitle = _get('title')
export const get_email = _get('email')
export const get_position = _get('position')
export const get_department = _get('department')
