import * as types from "./types"
import * as stateManager from "./stateManager"

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.INIT_DATA:
      return stateManager.initState()
    case types.RESET_DATA:
      return stateManager.resetState()
    case types.GET_REMINDER:
    case types.UPDATE_REMINDER:
      return stateManager.updateDataByKey(state, { isLoading: true, dataKey: "reminders", response: [] });
    case types.GET_REMINDER_SUCCESS:
    case types.UPDATE_REMINDER_SUCCESS:
      return stateManager.updateDataByKey(state, { isLoading: false, dataKey: "reminders", response: payload.reminders });
    case types.UPDATE_REMINDER_ERROR:
      return stateManager.updateDataByKey(state, { isLoading: false});
      default:
      return state
  }
}
