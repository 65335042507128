import React from 'react'
import ColAntd from "antd/es/grid/col";
import "./Col.less";

const Col = (props) => <ColAntd {...props}/>;

Col.propTypes = {};
Col.defaultProps = {};

export default Col;
