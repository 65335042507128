import React from "react";
import Tooltip from '../Tooltip';
import {QuestionCircleTwoTone} from '@ant-design/icons';
import PropTypes from "prop-types";
import './Form.less'

/* eslint-disable jsx-a11y/label-has-for */
const FormLabel = ({id, label, required = false, tooltip}) => (
  <label htmlFor={id}>
    {label}&nbsp;
    {required && <span className="required">*</span>}
    {tooltip && (
      <Tooltip title={tooltip} className={'tooltipText'} placement="topLeft" arrowPointAtCenter>
        <QuestionCircleTwoTone />
      </Tooltip>
    )}
  </label>
);

FormLabel.propTypes = {
  id: PropTypes.string,
  label: PropTypes.string,
  required: PropTypes.bool,
  tooltip: PropTypes.string
};

export default FormLabel;
