import React from "react";
import {Link} from "react-router-dom";
import classNames from "classnames";
import styles from "./Logo.module.scss"

const LogoLayout = ({homePage, isMini, className, isAdmin=false}) => <Link
  to={homePage || "/my-okrs"}
  className={classNames(className, styles.varComp, {[styles.varComp__mini]: isMini})}
><div className={classNames(styles.varComp__image, {[styles.varComp__image__admin]: isAdmin})} />
</Link>
LogoLayout.propTypes = {};
export default LogoLayout