import React from 'react'
import Card from './../../Card'
//import styles from './index.scss';

const headStyle = {
  backgroundColor: '#2c9afc',
  color: '#fff',
  fontSize: 'large',
  borderRadius: '8px 8px 0 0',
  background: 'linear-gradient(90deg, #216ED9 0%, #43B9E0 100%)',
  height: '40px'
};

const Fieldset = (props) => <Card headStyle={headStyle} {...props} />;

Fieldset.propTypes = {

};

export default Fieldset;
