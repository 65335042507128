import React from "react";
import AntdForm from "antd/es/form";
import Fieldset from "./Fieldset";
import FormLabel from "./FormLabel";
import "./Form.less";
export {default as LabelWithHelp} from './LabelWithHelp'
export {default as getValidateStatus} from './getValidateStatus'

const Form = props => <AntdForm {...props} onFinish={props.onFinish || props.onSubmit}/>;
export const FormItem = AntdForm.Item
export const FormList = AntdForm.List

FormItem.defaultProps = {
  colon: false
}

FormList.defaultProps = {
  colon: false
}

Form.Item = FormItem;
Form.List = FormList;


Form.propTypes = {
  ...AntdForm.propTypes
};

// Form.useForm = AntdForm.useForm;

export { Form as default, Fieldset, FormLabel };
