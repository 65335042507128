import React, {useEffect} from "react"
import {useSelector} from "react-redux";
import {useDispatch} from "react-redux";
import injectToStore, {actions, stateManager} from "./store"
import ReminderTab from "./RemiderTab"

const RemindersLayout = () => {
  const dispatch = useDispatch();
  const reminders = useSelector(stateManager.getReminder);
  useEffect(() => {
    dispatch(actions.getReminder())
    return () => dispatch(actions.resetData());
  }, [dispatch])

  return <div className="p-8">
    <ReminderTab reminders={reminders}/>
  </div>
}

RemindersLayout.propTypes = {}
export default injectToStore(RemindersLayout)
