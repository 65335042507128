import React, {useCallback, useMemo} from "react";
import _get from "lodash/fp/get";
import {useDispatch} from "react-redux";
import {push} from "connected-react-router";
import {Link} from "react-router-dom";
import styles from "./MenuItem.module.scss";

const MenuItem = ({itemData}) => {
  const dispatch = useDispatch();
  const handleClick = useCallback((event) => {
    event.preventDefault();
    if (itemData.path) {
      dispatch(push(itemData.path))
    } else if (itemData.url) {
      window.location = itemData.url
    }
  }, [dispatch, itemData]);

  const Tag = useMemo(() => itemData.path ? Link : "span", [itemData])
  return <>
    <li className={styles.menuItem} onClick={handleClick}>
      <Tag to={""}>{_get("label", itemData)}</Tag>
    </li>
  </>
}

MenuItem.propTypes = {}
export default MenuItem;