import React from 'react';
import AntdLayout from "antd/es/layout";
import './Layout.less'

const { Content : AntdContent, Header : AntdHeader, Footer : AntdFooter, Sider : AntdSider } = AntdLayout;

const Layout = (props) => <AntdLayout {...props} />;

Layout.propTypes = {
  ...AntdLayout.propTypes
};

const Header = (props) => <AntdHeader {...props} />;

const Content = props => <AntdContent {...props} />;

const Footer = props => <AntdFooter {...props} />;

const Sider = props => <AntdSider {...props} />;

export { Layout as default, Header, Content, Footer, Sider };
