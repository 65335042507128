import React, {useEffect} from "react"
import _get from "lodash/fp/get"
import {FormProvider, useForm} from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import Tabs, {TabPane} from "components/ui/Tabs";
import {parseValueForm} from "utils/parseValueForm"
import ReminderForm from "./ReminderForm"
import styles from "./RemiderTab.module.scss";
import {reminderFormSchema} from "./schema";
import {defaultValues} from "./defaultValues";
import {isCustomTime} from "./reminder.utils"

const ReminderTab = ({reminders}) => {
  const form = useForm({
    resolver: yupResolver(reminderFormSchema),
    mode: "onChange",
    defaultValues
  });

  useEffect(() => {
    if (reminders) {
      const newIntialValues = parseValueForm(defaultValues, reminders[0], form.setValue);
      newIntialValues.frequencyHead = isCustomTime(newIntialValues) ?  "custom" : _get("frequency", newIntialValues)
      newIntialValues.frequencyEvery = _get("frequency", newIntialValues)
      form.reset(newIntialValues);
    }
  }, [form, reminders]);

  return <>
    <FormProvider {...form}>
      <Tabs className={styles.varTabs}>
        <TabPane tab="Reminder" key={"reminder"}>
          <ReminderForm />
        </TabPane>
      </Tabs>
    </FormProvider>

  </>
}
ReminderTab.propTypes = {}
export default ReminderTab