import _map from "lodash/fp/map";
import _get from "lodash/fp/get";
import {put, call, all, select, takeLatest, takeEvery} from "@redux-saga/core/effects"
import {getNotificationListFetcher, setNotificationMaskAsReadFetcher} from "fetchers/user.fetcher"
import {UPDATE_LOGGED_USER_INFO} from "modules/Auth/GateWay/store/types.js"
import {rebuildConnection, getConnection} from "./notificationConnection"
import createPublishmentChannel from "./publishmentChannel"
import * as types from "./types";
import * as actions from "./actions";
import * as stateManager from "./stateManager";


function* reloadMessageListOnConnected() {
  yield put(actions.getMessageList())
}

function* attachEventHandler() {
  const connection = yield call(getConnection, reloadMessageListOnConnected)
  const publishChannel = yield call(createPublishmentChannel, connection)
  yield takeEvery(publishChannel, addNewMessage)
}

function* addNewMessage(event) {
  try {
    yield put(actions.addNewMessage(event))
  } catch (e) {
    console.log(e)
  }
}

function* initData() {
  yield put(actions.getMessageList())
  yield call(attachEventHandler)
}

function* sendMessageListRequest() {
  try {
    const response = yield call(getNotificationListFetcher)
    console.log("response", response)
    yield put(actions.getMessageListSuccess(response.results))
  } catch (e) {
    console.log(e)
  }
}

function* sendMarkAsReadRequest({payload: id}) {
  try {
    yield call(setNotificationMaskAsReadFetcher, id)
    yield put(actions.markReadByIdSuccess(id))
  } catch (e) {
    console.log(e)
  }
}

function* watchMarkAllAsReadRequest() {
  try {
    const unreadMsg = yield select(stateManager.getUnreadMessage)
    const unreadIds = _map(_get("id"), unreadMsg)
    yield put(actions.markAllReadSuccess())
    yield all(_map((id => call(sendMarkAsReadRequest, {payload: id})), unreadIds))
  } catch (e) {
    console.log(e)
    yield put(actions.markAllReadFail())
  }
}

function* watchUpdateLoggedUserInfo() {
  try {
    yield put(actions.resetData())
    const result = yield call(rebuildConnection)
    console.log(result)
    yield put(actions.initData())
  } catch(e) {
    console.log('watchUpdateLoggedUserInfo')
    console.log(e)
  }
}


export default function* rootSaga() {
  yield takeLatest(types.GET_MESSAGE_LIST, sendMessageListRequest)
  yield takeLatest(types.MARK_AS_READ_ALL, watchMarkAllAsReadRequest)
  yield takeLatest(types.MARK_AS_READ, sendMarkAsReadRequest)
  yield takeLatest(types.INIT_DATA, initData)
  yield takeLatest(UPDATE_LOGGED_USER_INFO, watchUpdateLoggedUserInfo)
}
