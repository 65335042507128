import React, {lazy, Suspense} from 'react'
import ErrorBoundary from './../ErrorBoundary'
import Skeleton from './../Skeleton'


const loadable = (importFunc, {fallback = null} = {fallback: <Skeleton/>}) => {
  const LazyComponent = lazy(importFunc)
  /* eslint-disable react/display-name */
  return props => (
    <ErrorBoundary>
      <Suspense fallback={fallback}>
        <LazyComponent {...props} />
      </Suspense>
    </ErrorBoundary>
  )
}

export default loadable
