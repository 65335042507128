/* eslint-disable */
export default (fullname) => {
  if (!fullname) return null;
  let t, r;
  for (fullname = fullname.split(" "), t = "", r = 0; r in fullname; )
    (t += fullname[r][0]), r++;
  if(t.length>2){
    t = t[0] + t[t.length-1]
  }
  return t.toUpperCase();
};
