import React, {useCallback} from "react"
import {useLocation} from "react-router"
import {useDispatch} from "react-redux"
import {push} from "connected-react-router";
import Button from "components/ui/Button"
import Card from "components/ui/Card"
import VaroomImg from "components/ui/VaroomImg"
import noPermission from "assets/images/403NoPermission.png"


const NoPermission = () => {
  const dispatch = useDispatch()
  const location = useLocation()
  const expired = location && location.search && location.search.indexOf("expired")
  const onLogout = useCallback(() => {
    dispatch(push("/logout"));
  }, [dispatch])

  return (<>
    <div style={{margin: "40px"}}>
      <Card className={"text-center"}>
        <VaroomImg src={noPermission} style={{maxWidth: "200px"}} className="m-auto" />
        <h2 className={"text-2xl"}>{expired ? "Token expired" : "Permission denied"}</h2>
        {expired
          ? <p>Your session has been expired, please login to continue.</p>
          : <p>You do not have permission to access this page. <br/> Please refer to your system administrator.</p>
        }

        <Button onClick={onLogout} size={"large"} className="bg-[#366be6]" type={"primary"}>{expired ? "Login" : "Sign in with another account"}</Button>
      </Card>
    </div>
  </>)
}

NoPermission.propTypes = {}

  export default NoPermission



