import ProfileLayout from "./ProfileLayout.Loadable"
import {FEATURE_KEY} from "./constants"

const ROUTES = [{
  path: `/profile`,
  exact: true,
  component: ProfileLayout,
  layoutConfig: {
    isUsedAppShell: true,
    title: "Profile",
    routes: [
      {
        breadcrumbName: "Profile"
      }
    ]
  },
  featureKey: FEATURE_KEY
}]

export default ROUTES
