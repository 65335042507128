import React from 'react';
import AntdList from "antd/es/list";
import Meta from './Meta';

const { Item : AntdListItem } = AntdList;

const Item = (props) => <AntdListItem {...props} />;

Item.propTypes = {};

export { Item as default, Meta };
