import React from "react";
import Checkbox from "antd/es/checkbox";
import "./CheckBox.less";

// if dont wrap inside component, when use form component + getFieldDecorator will be show a messege:
//antd form Function components cannot be given refs
class AntdCheckbox extends React.Component {
  render() {
    return <Checkbox {...this.props} />;
  }
}

const { Group: AntdCheckboxGroup } = Checkbox;
AntdCheckbox.Group = function Group(props) {
  return <AntdCheckboxGroup {...props} />;
};

export default AntdCheckbox;
