import React from 'react'
import {ToastContainer} from 'react-toastify'
import './ToastMessage.scss'


const ToastMessage = () => (<ToastContainer autoClose={5000}/>)

ToastMessage.propTypes = {}

export default ToastMessage



