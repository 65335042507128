import {getAuthFromStorage, getUserFromStorage, removeUserFromStorage, setUserToStorage} from "./syncUserToStorage"
import {createSelector} from "reselect"
import produce from "immer"
import {MODULE_STATE_NAME} from "./constants"
import _get from "lodash/fp/get"
import _pipe from "lodash/fp/pipe"
import _setFp from "lodash/fp/set"
// convert to mutable
const _set = _setFp.convert({'immutable': false})

const STATE_DEFAULT_VALUE = {
  globalLoading: false,
  notifyInfo: null,
  currentUser: null,
  layoutConfig: null,
  addedBreadcrumb: undefined
}




export const initState = () => ({...STATE_DEFAULT_VALUE, currentUser: getUserFromStorage()})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
const getLocalState = (state) => {
  const local = _get([MODULE_STATE_NAME], state)
  return local || initState()
}

export const isLoading = createSelector(
  getLocalState,
  _get(['globalLoading'])
)

export const getNotifyInfo = createSelector(
  getLocalState,
  _get(['notifyInfo'])
)

export const getLayoutConfig = createSelector(
  getLocalState,
  _get(['layoutConfig'])
)

export const getLayoutConfig__pageTitle = createSelector(
  getLayoutConfig,
  (data) => {
    const title = _get(['title'])(data)
    return title  ? `VAROOM | ${title}` : 'VAROOM'
  }
)

export const getAddedBreadcrumb = createSelector(
  getLocalState,
  _get('addedBreadcrumb')
)

export const getLayoutConfig__breadcrumb = createSelector(
  getLayoutConfig,
  getAddedBreadcrumb,
  (layoutConfig, {routes=[], isMerged=false}={}) => {
    const rootRoutes = _get('routes', layoutConfig) || []
    return isMerged ? [...rootRoutes, ...routes] : rootRoutes
  }
)

export const getCurrentUser = createSelector(
  getLocalState,
  _get(['currentUser'])
)


export const isLogin = createSelector(
  getLocalState,
  _get(['currentUser'])
  // (state) => {
  //   return _get(['currentUser'], state)
  // }
)

/******************************** UPDATER ********************************/

export const updaterLoading = (state, {isLoading}) => produce(state, _set('globalLoading', isLoading))
export const updaterNotifyInfo = (state, {notifyInfo}) => produce(state, _set('notifyInfo', notifyInfo))
export const updaterLayoutConfig = (state, {layoutConfig}) => produce(state, _pipe([
  _set('layoutConfig', layoutConfig),
  _set('addedBreadcrumb', undefined)
]))

export const updaterLayoutConfigBreadcrumb = (state, {routes, isMerged}) => produce(state, _set('addedBreadcrumb', ({routes, isMerged})))


export const updaterCurrentUser = (state, {currentUser}) => produce(state, (state) => {
 setUserToStorage(currentUser);
  return _set('currentUser', currentUser)(state)
})

export const updaterResetUser = (state, {callback}) => produce(state, (state) => {
  removeUserFromStorage();
  produce(state, _pipe([
    _set('currentUser', null),
  ]))
  callback && callback()
  return state
})



export const getUserToken = () => {
  return getAuthFromStorage() || null
}
