import React, {useMemo} from "react";
import classnames from "classnames";
import _capitalize from "lodash/fp/capitalize";
import Avatar from "components/ui/Avatar";
import Tooltip from "components/ui/Tooltip";
import name2shortcut from "utils/name2shortcut";
import {getFullName, getFirstName, getLastName, getEmail, getImageURL, getRole} from "domainData/user.domainData";
import styles from "./UserInfo.module.scss";

const UserInfo = (props) => {
  const { user } = props;
  const nameDisplay = useMemo(() => getFullName(user) || getFirstName(user) + " " + getLastName(user) || getEmail(user) || "", [user]);

  const avatar = useMemo(() => {
    if (getImageURL(user)) {
      return <Avatar className={styles.avatar} src={getImageURL(user)} size={36} />;
    }

    return (
      <Avatar className={styles.avatar} size={36}>
        {name2shortcut(nameDisplay)}
      </Avatar>
    );
  }, [nameDisplay, user]);

  return <>
    {avatar}
    <Tooltip title={nameDisplay}>
      <div
        className={classnames(styles.fullName, "ant-display-name")}
      >
        {nameDisplay}
        <p className={styles.role}>{_capitalize(getRole(user))}</p>
      </div>
    </Tooltip>
  </>
}

UserInfo.propTypes = {}
export default UserInfo