import React, {useEffect} from "react";
import {useSelector, useDispatch} from "react-redux";
import {Redirect} from "react-router-dom";
import {push} from "connected-react-router";
import {getCurrentUser} from "modules/AppCore/store/stateManager";
import Card from "components/ui/Card";
import VaroomImg from "components/ui/VaroomImg";
import NotFoundPageImg from "assets/images/under-construction.png";

const NotFoundPage = () => {
  const currentUser = useSelector(getCurrentUser);
  const dispatch = useDispatch();
  useEffect(() => {
    if (!currentUser) {
      dispatch(push("/login"))
    }
  }, [currentUser, dispatch])

  if (window.location.pathname === "/") {
    return (<Redirect to="/my-okrs"/>)
  }
  return (
    <Card>
      <article style={{padding: "10px 50px", textAlign: "center"}}>
        <VaroomImg src={NotFoundPageImg} style={{maxWidth: "50%", padding: "30px"}}/>
      </article>
    </Card>
  )
}
NotFoundPage.propTypes = {}
export default NotFoundPage