import _pipe from "lodash/fp/pipe"
import PropTypes from "prop-types"
import React, {useEffect} from "react"
import {injectIntl} from "react-intl";
import {useMessage} from "./useMessage"
import styles from "./Loading.module.scss"


const Loading = ({loadingInfo, ...props}) => {
  const {intl} = props;
  const message = useMessage(loadingInfo)
  console.log(styles)


  useEffect(() => {
    _pipe([
      () => document.getElementsByClassName("globalLoading"),
      (elms) => elms && elms.length > 1 && (elms[0].style.visibility = "hidden")
    ])()
  }, [])

  return (
    <div className={`globalLoading ${styles.loadingWrapper}`}>
      <p
        className={styles.loadingWrapper__message}>{message && message.labelId ? intl.formatMessage({id: message.labelId}) : message}</p>
      <div className={styles.loadingWrapper__loader}>
        <span className={styles.loadingWrapper__block1}/>
        <span className={styles.loadingWrapper__block2}/>
        <span className={styles.loadingWrapper__block3}/>
        <span className={styles.loadingWrapper__block4}/>
        <span className={styles.loadingWrapper__block5}/>
        <span className={styles.loadingWrapper__block6}/>
        <span className={styles.loadingWrapper__block7}/>
        <span className={styles.loadingWrapper__block8}/>
        <span className={styles.loadingWrapper__block9}/>
        <span className={styles.loadingWrapper__block10}/>
        <span className={styles.loadingWrapper__block11}/>
        <span className={styles.loadingWrapper__block12}/>
        <span className={styles.loadingWrapper__block13}/>
        <span className={styles.loadingWrapper__block14}/>
        <span className={styles.loadingWrapper__block15}/>
        <span className={styles.loadingWrapper__block16}/>
      </div>
    </div>
  )
}

Loading.propTypes = {
  styles: PropTypes.any,
  loadingInfo: PropTypes.any
}


export default injectIntl(Loading)
