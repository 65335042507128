import _isEqual from "lodash/fp/isEqual";
import _get from "lodash/fp/get";
import moment from "moment";

export const isCustomTime = (data) => _get("isCustom", data)
export const weeklySelector = (data) => _isEqual(_get("frequencyEvery", data), "weekly");
export const convertTimeOfDay = (time) => moment(time).minute(0).seconds(0).milliseconds(0).toISOString()

export const formatFormData = (formData) => {
  formData.timeOfDay = convertTimeOfDay(formData.timeOfDay);
  if(isCustomTime(formData)) {
    formData.frequency = formData.frequencyEvery
  } else {
    formData.frequency = formData.frequencyHead
  }
  delete formData.frequencyEvery;
  delete formData.frequencyHead;

  return formData;
}