import _debounce from 'lodash/fp/debounce'
import _invoke from 'lodash/fp/invoke'
import _pipe from 'lodash/fp/pipe'
import _map from 'lodash/fp/map'
import _compact from 'lodash/fp/compact'
import _toString from 'lodash/fp/toString'
import _indexOf from 'lodash/fp/indexOf'
import React, {useState, useEffect, useCallback, useMemo} from 'react'
import Spin from '../Spin'
import PropTypes from 'prop-types'
import _get from 'lodash/fp/get'
import Select from './Select'
import AntdSelect from 'antd/es/select'
import _some from 'lodash/fp/some'
import { FormattedMessage } from 'react-intl'

const isExcludedMe = (list, key) => _indexOf(_toString(key), _map(_toString, list)) > -1

const SelectWithDataSourceUi = ({
  dataSource = [],
  isLoading,
  isLoadError,
  getItemTitle,
  getItemValue,
  itemValueKey = 'key',
  itemTitleKey = 'title',
  disabled,
  placeholderId,
  placeholder,
  excludedItems,
  renderItems,
  ...props
}) => {
  const menuItems = useMemo(() => renderItems
    ? renderItems(dataSource)
    : _pipe([
      _map(item => {
        const key = getItemValue ? getItemValue(item) : _get(itemValueKey, item)
        return isExcludedMe(excludedItems, key) ? null : (
          <AntdSelect.Option key={key} item={item}>
            {getItemTitle ? getItemTitle(item) :  _get(itemTitleKey, item) || key}
          </AntdSelect.Option>
        )
      }),
      _compact])(dataSource), [dataSource])

  return (
    <Select
      {...props}
      loading={isLoading}
      disabled={isLoading || isLoadError ? true : disabled}
      placeholder={placeholderId ? <FormattedMessage id={placeholderId}/> : placeholder}>
      {menuItems}
    </Select>
  )
}

SelectWithDataSourceUi.propTypes = {
  dataSource: PropTypes.array,
  disabled: PropTypes.any,
  isLoading: PropTypes.bool,
  isLoadError: PropTypes.bool,
  getItemTitle: PropTypes.func,
  getItemValue: PropTypes.func,
  renderItems: PropTypes.func,
  itemValueKey: PropTypes.string,
  itemTitleKey: PropTypes.string,
  placeholderId: PropTypes.string,
  placeholder: PropTypes.any,
  excludedItems: PropTypes.any
}

const fnFilterOptionByContent = (input = '', {key = '', props: {children = ''}}) =>
  input && input.length > 1
    ? _some(str => str.includes(input.toLowerCase()), [children.toLowerCase(), key.toLowerCase()])
    : true

const SelectWithDataSource = ({
  getDataSource,
  getDataSourceBySearchKey,
  filterOption=false,
  useDefaultFilterOption,
  reInitCount,
  fnAddDefaultsItems,
  ...props
}) => {
  const [dataSource, setInternalDataSource] = useState([])
  const [isUnmounted, set_isUnmounted] = useState(false)
  const [isLoading, setInternalLoading] = useState(true)
  const [searchingProcess, setInternalSearch] = useState(undefined)
  const [isLoadError, setIsLoadError] = useState(false)

  const setInternalDataSourceCb = useCallback(dataSource => !isUnmounted && setInternalDataSource(dataSource || [])
    , [setInternalDataSource, isUnmounted])

  const onSearch = useCallback(
    _debounce(1000, searchString => {
      const processing = getDataSourceBySearchKey((searchString || '').trim() )

      processing.then(setInternalDataSourceCb)
        .finally(() => setInternalSearch(undefined))

      setInternalDataSourceCb([])
      setInternalSearch(processing)
    }),
    [getDataSourceBySearchKey]
  )

  // load init data
  useEffect(() => {
    const geSource = getDataSourceBySearchKey || getDataSource
    const processing = geSource && geSource()

    processing
      .then((response) => fnAddDefaultsItems ? fnAddDefaultsItems(response) :  response)
      .then(setInternalDataSourceCb)
      .catch(() => setIsLoadError(true))
      .finally(() => setInternalLoading(false))

    return () => _invoke('reject', processing)
  }, [reInitCount, getDataSourceBySearchKey, getDataSource])

  useEffect(() => () => set_isUnmounted(true), [])

  return (
    <SelectWithDataSourceUi
      {...props}
      filterOption={useDefaultFilterOption ? fnFilterOptionByContent : filterOption}
      dataSource={dataSource}
      isLoading={isLoading}
      isLoadError={isLoadError}
      notFoundContent={searchingProcess ? <Spin size="small"/> : undefined}
      showSearch={true}
      onSearch={getDataSourceBySearchKey ? onSearch : null}
    />
  )
}

SelectWithDataSource.propTypes = {
  getDataSource: PropTypes.func,
  getDataSourceBySearchKey: PropTypes.func,
  onChange: PropTypes.func,
  renderItems: PropTypes.func,
  useDefaultFilterOption: PropTypes.bool,
  filterOption: PropTypes.any,
  fnAddDefaultsItems: PropTypes.any,
  reInitCount: PropTypes.any
}

SelectWithDataSource.defaultProps = {
  style: {width: '100%'},
  autoClearSearchValue: true
}

export default SelectWithDataSource
