import React from "react";
import CardAntd from "antd/es/card";
import "./Card.less";

const Card = props => <CardAntd {...props} />;

const {Meta: MetaAntd} = CardAntd;
Card.Meta = function Meta(props) {
  return <MetaAntd {...props} />;
};

export default Card;

