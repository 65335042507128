import * as types from './types'
import * as stateManager from './stateManager'

// Reducer
export default function reducer(state = stateManager.initState(), {type, payload}) {
  switch (type) {
    case types.INIT_DATA:
      return stateManager.updaterInitData(state)
    case types.RESET_DATA:
      return stateManager.resetState()
    case types.ADD_NEW_MESSAGE:
      return stateManager.updaterAddMessage(state, payload)
    case types.GET_MESSAGE_LIST_SUCCESS:
      return stateManager.updaterMessageList(state, payload)
    case types.GET_MESSAGE_LIST_ERROR:
      return stateManager.updaterMessageList(state, [])
    case types.MARK_AS_READ:
      return stateManager.updaterMarkAsReadById(state, payload)
    case types.MARK_AS_READ_ALL_SUCCESS:
      return stateManager.updaterMarkAllAsRead(state)
    default:
      return state
  }
}