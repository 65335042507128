import _get from 'lodash/fp/get'
import _isString from 'lodash/fp/isString'
import _isArray from 'lodash/fp/isArray'
import _random from 'lodash/fp/random'
import _size from 'lodash/fp/size'
import {DEFAULT_LOADING_QUOTES} from './constants'
import {useState, useEffect, useCallback} from 'react'


const getRandomMessage = (messageArr) => _get(_random(0, _size(messageArr) - 1), messageArr)

export const useMessage = ({message} = {}) => {
  const getMsg = useCallback(() => getRandomMessage(_isArray(message) ? message : DEFAULT_LOADING_QUOTES), [message])
  const [msg, setMsg] = useState(_isString(message) ? message : getMsg())

  useEffect(() => {
    if (_isString(message)) {
      return
    }
    const interval = setInterval(() => setMsg(getMsg()), 1000 * 10)
    return () => clearInterval(interval)
  }, [getMsg, message])

  return msg
}