import AllOkrsLayout from "./AllOkrsLayout.Loadable"
import {FEATURE_KEY} from "./store/constants"

const ROUTES = [{
  path: `/all-okrs`,
  exact: true,
  component: AllOkrsLayout,
  layoutConfig: {
    isUsedAppShell: true,
    title: "AllOrks",
    routes: [
      {
        breadcrumbName: "Explore ORG OKRs"
      }
    ]
  },
  featureKey: FEATURE_KEY
}]

export default ROUTES
