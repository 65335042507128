import {useCallback, useMemo} from "react"
import {useSelector} from "react-redux";
import _eq from "lodash/fp/eq";
import _find from "lodash/fp/find";
import {getRole, getPermissions, getPermission} from "domainData/user.domainData";
import {ADMIN_ROLE} from "constants/userRoles";
import {getCurrentUser} from "modules/AppCore/store/stateManager";
import {featureCheckerFn} from "./helpers"

export const useFeatureChecker = (featureKey) => {
  const currentUser = useSelector(getCurrentUser);
  const canUseCheckerFn = useMemo(() => featureCheckerFn(currentUser), [currentUser])
  const canUseFn = useCallback((featureKey) => {
    const {canUse} = canUseCheckerFn(featureKey)
    return canUse
  }, [canUseCheckerFn])
  return useMemo(() => ({
      ...canUseCheckerFn(featureKey),
      canUseFn,
      canUseCheckerFn
    }
  ), [canUseFn, featureKey, canUseCheckerFn])
}

export const isAdminRoles = () => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const currentUser = useSelector(getCurrentUser);
  return !(!currentUser || !getRole(currentUser) || !_eq(getRole(currentUser), ADMIN_ROLE));
}

export const usePermissionFeature = (featureKey, permission) => {
  const currentUser = useSelector(getCurrentUser);
  const findPermission = _find(({feature: featureKey}), getPermissions(currentUser));
  if(!findPermission) return false
  return (getPermission(findPermission) | permission) === getPermission(findPermission);
}

