import * as signalR from '@microsoft/signalr'
import {BASE_URL} from "utils/ssoSetting"
import {HeaderSetting} from 'fetchers/setting'

let connection = null

export function closeConnection() {
  try {
    if (connection) {
      const processing = connection.stop()

      processing.then(() => connection = null)

      return processing
    }
  } catch (e) {
    console.log('[notificationConnection][closeConnection] fail')
    return Promise.reject('error')
  }
}

export const rebuildConnection= () => new Promise((resolve) => {
  closeConnection().then(() => {
    setTimeout(() => resolve(getConnection()), 1000)
  })
})

export const getConnection = () => {
  if(!connection) {
    connection = new signalR.HubConnectionBuilder()
      .withUrl(BASE_URL, {accessTokenFactory: () => HeaderSetting.rawToken})
      .withAutomaticReconnect()
      .configureLogging(signalR.LogLevel.Information)
      .build();
    async function startConnection(onConnectedCb) {
      try {
        await connection.start();
        console.log('[notificationConnection][startConnection] connected')
        onConnectedCb && onConnectedCb()
      } catch (err) {
        console.log('[notificationConnection][startConnection] fail')
        setTimeout(startConnection, 5000);
      }
    }

    connection.onclose(async () => {
      await startConnection();
    });

    startConnection();
  }
  return connection
}