import * as types from './types'

// Action Creators
export function initData() {
  return { type: types.INIT_DATA }
}

export function resetData() {
  return { type: types.RESET_DATA }
}

export function getMessageList() {
  return {type: types.GET_MESSAGE_LIST}
}

export function addNewMessage(event) {
  return {type: types.ADD_NEW_MESSAGE, payload: event }
}

export function getMessageListSuccess(messageList) {
  return {type: types.GET_MESSAGE_LIST_SUCCESS, payload: messageList}
}

export function markReadById(id) {
  return { type: types.MARK_AS_READ, payload: id }
}
export function markReadByIdSuccess(id) {
  return { type: types.MARK_AS_READ_SUCCESS, payload: id }
}

export function markAllRead() {
  return {type: types.MARK_AS_READ_ALL}
}

export function markAllReadSuccess() {
  return {type: types.MARK_AS_READ_ALL_SUCCESS}
}

export function markAllReadFail() {
  return {type: types.MARK_AS_READ_ALL_ERROR}
}