import {call, put, takeLatest} from "@redux-saga/core/effects"
import * as appActions from "appActions";
import {
  getReminder,
  updateReminder
} from "fetchers/reminder.fetchers";
import * as types from "./types"
import * as actions from "./actions";
const {setNotify} = appActions;

function* sendRequestGetReminder() {
  try{
    const reminder = yield call(getReminder);
    yield put(actions.getReminderSuccess(reminder))
  } catch (error){
    console.log(error);
    yield put(actions.getReminderError(error))
  }
}

function* sendRequestUpdateReminder({payload}) {
  const {formData} = payload;
  try{
    const reminder = yield call(updateReminder, {formData});
    yield put(actions.updateReminderSuccess(reminder))
    yield put(setNotify("success", "Updated reminders successfully."))
  } catch (error){
    console.log(error);
    yield put(actions.updateReminderError(error))
    yield put(setNotify("error", "Update reminders failed."))
  }
}

export default function* rootSaga() {
  yield takeLatest(types.GET_REMINDER, sendRequestGetReminder)
  yield takeLatest(types.UPDATE_REMINDER, sendRequestUpdateReminder)
}
