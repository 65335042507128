import React from "react";
import Dropdown from "components/ui/Dropdown";
import Menu from "components/ui/Menu";
import {useSelector} from "react-redux";
import classnames from "classnames";
import {SIDEBAR_PROFILE, SIDEBAR_ACCOUNT_GREY} from "components/shared/AppShell/constants";
import Notification from "../Notification";
import NotificationList from "components/shared/NotificationList"
import {getCurrentUser} from "modules/AppCore/store/stateManager";
import UserInfo from "./UserInfo";
import MenuItem from "./MenuItem";
import styles from "./UserProfileLayout.module.scss";
import UserTitle from "./UserTitle";
/*import AdminRequest from "./AdminRequest";*/

const UserProfileLayout = () => {
  const currentUser = useSelector(getCurrentUser);
  return <div className={styles.layout}>
    <Dropdown
      placement="topLeft"
      trigger={["click"]}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      className={classnames(styles.dropdown, "ant-dropdown-user")}
      overlay={(<Menu className={`${styles.menu} ant-dropdown-menu`}>
        <UserTitle user={currentUser} />
        {SIDEBAR_PROFILE.map((item, index) => (<MenuItem key={`profile-menu-${index}`} itemData={item} />))}
        {/*<AdminRequest />*/}
        {SIDEBAR_ACCOUNT_GREY.map((item, index) => (<MenuItem key={`account-menu-${index}`} itemData={item} />))}
      </Menu>)}
    >
      <div>
        <div
          style={{display: "flex", flex: 1, alignItems: "center"}}
        >
          <UserInfo
            user={currentUser}
          />
        </div>
      </div>
    </Dropdown>
    <Dropdown
      placement="bottomLeft"
      overlay={<NotificationList />}
      trigger={["click"]}
      getPopupContainer={triggerNode => triggerNode.parentNode}
      arrow
    >
      <div>
        <Notification/>
      </div>
    </Dropdown>
  </div>
}

UserProfileLayout.propTypes = {}
export default UserProfileLayout