import _get from 'lodash/fp/get'
import _indexOf from 'lodash/fp/indexOf'
import _map from 'lodash/fp/map'
import _some from 'lodash/fp/some'
import _toString from 'lodash/fp/toString'


export const fnFilterOptionByContent = (input = '', {key = '', props: {children = ''}}) =>
  input && input.length > 1
    ? _some(str => str.includes(input.toLowerCase()), [children.toLowerCase(), key.toLowerCase()])
    : true

export const isExcludedMe = (key, {excludedItems}) => excludedItems && excludedItems.length
  ? _indexOf(_toString(key), _map(_toString, excludedItems)) > -1
  : false

export const getItemValue = (item, {getItemValue, itemValueKey}) => getItemValue ? getItemValue(item) : _get(itemValueKey, item)
export const getItemTitle = (item, {getItemTitle, itemTitleKey}) => getItemTitle ? getItemTitle(item) : _get(itemTitleKey, item)
