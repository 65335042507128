import produce from "immer";
import {createSelector} from "reselect"
import {MODULE_STATE_NAME} from "./constants"
import _getOr from "lodash/fp/getOr"
import _pipe from "lodash/fp/pipe"
import _get from "lodash/fp/get"
import _setFp from "lodash/fp/set";
const _set = _setFp.convert({ immutable: false });
// convert to mutable

const STATE_DEFAULT_VALUE = {
  uiConfig: null,
  reminders: []
}

export const initState = () => ({...STATE_DEFAULT_VALUE})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
const getLocalState = (state) => _get([MODULE_STATE_NAME], state) || initState()

export const getReminder = createSelector(
  getLocalState,
  _getOr({}, ["reminders"])
)

export const getUiConfig = createSelector(
  getLocalState,
  _getOr({}, ["uiConfig"])
)

/******************************** UPDATER ********************************/
export const updateDataByKey = (state, { dataKey, response, isLoading, error }) => produce(
  state,
  _pipe([
    _set("uiConfig", isLoading || error ? { error, isLoading } : undefined),
    ...(response ? [_set(dataKey, response)] : []),
  ])
);
