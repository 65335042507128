import React from 'react';
import RowAntd from "antd/es/grid/row";
import './../Grid'


const Row = (props) => <RowAntd {...props}/>;

Row.propTypes = {};
Row.defaultProps = {};

export default Row;
