import React, {useMemo, useState, useCallback} from "react";
import {push} from "connected-react-router";
import {useDispatch} from "react-redux";
import {useLocation} from "react-router-dom";
import _without from "lodash/fp/without";
import _startsWith from "lodash/fp/startsWith";
import _lowerCase from "lodash/fp/lowerCase";
import _get from "lodash/fp/get";
import Menu from "components/ui/Menu";
import {useFeatureChecker} from 'components/shared/PermissionManager'
import MainMenuItem from "./MainMenuItem";
import {SIDEBAR_ITEMS} from "../../constants";
import {getItemKey, findSelectedKeys} from "./utils";
import styles from "./MainMenu.module.scss";

const useOpenKeyHook = () => {
  const [openKeys, setOpenKeys] = useState([]);
  const openSubMenu = useCallback((newOpenKeys) => {
    setOpenKeys(_without(openKeys, newOpenKeys))
  }, [openKeys, setOpenKeys])
  return [openKeys, openSubMenu]
}

const MainMenu = () => {
  const dispatch = useDispatch();
  const [mode] = useState("inline");
  const {pathname, search} = useLocation();
  const {canUseCheckerFn} = useFeatureChecker()
  const selectedKeys = useMemo(() => findSelectedKeys(SIDEBAR_ITEMS, pathname, search), [pathname, search]);
  const [openKeys, onOpenChange] = useOpenKeyHook(selectedKeys || [], mode);

  const renderMenuItem = useCallback((menuItem) => {
    const itemKey = getItemKey(menuItem);
    const {canUse} = canUseCheckerFn(menuItem.featureKey);
    return (canUse)
      ? (<Menu.Item key={itemKey}><MainMenuItem menuItem={menuItem}/></Menu.Item>)
      : null
  }, [canUseCheckerFn])

  const onSelect = useCallback(({key, domEvent}) => {
    if (_lowerCase(_get('target.tagName', domEvent)) !== 'a' && _startsWith('/', key)) {
      dispatch(push(key))
    }
  }, [dispatch])

  return <>
    <Menu
      mode={mode}
      onClick={onSelect}
      openKeys={openKeys}
      defaultSelectedKeys={selectedKeys}
      onOpenChange={onOpenChange}
      className={styles.varComp}
      selectedKeys={selectedKeys}
    >
      {SIDEBAR_ITEMS.map(item => renderMenuItem(item))}
    </Menu>
  </>
};

MainMenu.propTypes = {}
export default MainMenu;