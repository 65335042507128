/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

import _map from "lodash/fp/map"
import React, {useMemo} from "react"
import {Route, Switch} from "react-router-dom"
import {modulesRoutes} from "modules/routes"
import NotFoundPage from "components/shared/NotFoundPage";
import ToastMessage from "components/shared/ToastMessage"
import PrivatePage from "./PrivatePage";
import RouteWrapper from "./RouteWrapper"
import DocumentHead from "./DocumentHead"
import GlobalLoading from "./GlobalLoading"
import NoPermission from "./NoPermission";


export default function App() {
  const privateRoutes = useMemo(() => _map(
      ({component: Component, featureKey, ...route}) => {
        return <RouteWrapper
          key={route.path}
          featureKey={featureKey}
          component={Component} {...route}/>
      },
      modulesRoutes.privatePages)
    , []);

  return (
    <React.Fragment>
      <DocumentHead/>
      <Switch>
        {modulesRoutes.publicPages.map(({component: Component, ...route}) => <Route key={route.path} component={Component} {...route}/>)}
        <PrivatePage>
          <Switch>
            <Route path="/no-permission" component={NoPermission}/>
            {privateRoutes}
            <Route path="*" component={NotFoundPage}/>
          </Switch>
        </PrivatePage>
      </Switch>
      <GlobalLoading/>
      <ToastMessage/>
    </React.Fragment>
  )
}
