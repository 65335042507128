import {defineMessages} from "utils/varoom-translation.utils"

export default defineMessages({
  viewProfile: {
    id: "AppShell.userProfile.viewProfile",
    defaultMessage: "View Profile"
  },
  signOut: {
    id: "AppShell.userProfile.signOut",
    defaultMessage: "Sign Out"
  },
})
