export const parseValueForm = (defaultValues, values, cb) => {
  let newValues = {};

  if (defaultValues && values) {
    for (let key of Object.keys(defaultValues)) {
      const newValue = values[key] || defaultValues[key];
      newValues = { ...newValues, [key]: newValue };
      cb && cb(key, newValue);
    }
  }

  return newValues;
};

export function dirtyValues(dirtyFields, allValues) {
  if (dirtyFields === true || Array.isArray(dirtyFields)) {
    return allValues;
  }
  return Object.fromEntries(
    Object.keys(dirtyFields).map((key) => [key, dirtyValues(dirtyFields[key], allValues[key])])
  );
}
