import _getOr from "lodash/fp/getOr"
import _get from "lodash/fp/get"
import _pipe from "lodash/fp/pipe"
import _size from "lodash/fp/size"
import _filter from "lodash/fp/filter"
import _find from "lodash/fp/find"
import _setFp from "lodash/fp/set"

import {createSelector} from "reselect"
import produce from "immer"
import {MODULE_STATE_NAME} from "./constants"

// convert to mutable
const _set = _setFp.convert({"immutable": false})

const STATE_DEFAULT_VALUE = {
  messageList: [],
  isInitData: false,
  params: {
    isDesc: true,
  }
}

export const initState = () => ({...STATE_DEFAULT_VALUE})
export const resetState = () => ({...STATE_DEFAULT_VALUE})

/******************************** GETTER ********************************/
const getLocalState = (state) => state[MODULE_STATE_NAME] || initState()

export const isInitData = createSelector(
  getLocalState,
  _get("isInitData")
)

export const getMessageList = createSelector(
  getLocalState,
  _getOr([], "messageList")
)
export const getUnreadMessage = _pipe(
  getMessageList,
  _filter({read: false})
)

export const getUnreadMessageNumber = _pipe(
  getUnreadMessage,
  _size
)

/******************************** UPDATER ********************************/
export const updaterInitData = (state) => produce(state, _set("isInitData", true))
export const updaterMessageList = (state, messageList) => produce(state, _pipe([_set("isInitData", true), _set("messageList", messageList)]))
export const updaterMarkAsReadById = (state, id) => produce(state, (draft) => {
  const msg = _find({id: id}, draft.messageList)
  msg && (msg.read = true)
})
export const updaterMarkAllAsRead = (state) => produce(state, (draft) => {
  draft.messageList.forEach((item) => _set('read', true)(item))
})
export const updaterAddMessage = (state, newEvent) => produce(state, (draft) => {
  draft.messageList = [newEvent, ...draft.messageList]
})
