import React from 'react'
import AntdEmpty from 'antd/es/empty';
import './Empty.less';

const Empty = props => <AntdEmpty {...props} />;

Empty.PRESENTED_IMAGE_SIMPLE = AntdEmpty.PRESENTED_IMAGE_SIMPLE
Empty.PRESENTED_IMAGE_DEFAULT = AntdEmpty.PRESENTED_IMAGE_DEFAULT

export {Empty as default}
