import _groupBy from "lodash/fp/groupBy"
import {ROUTES as AllOrks} from "./AllOkrs"
import MY_OKRS_ROUTERS from "./MyOkrs"
import {ROUTES as People} from "./People"
// import {ROUTES as Review} from "./Review"
import {ROUTES as Profile} from "./Profile"
import {ROUTES as Reminders} from "./Reminders"
import {ROUTES as Login} from "./Auth/Login"
import {ROUTES as Logout} from "./Auth/Logout"
import {ROUTES as GateWay} from "./Auth/GateWay"
import { ROUTES as AI } from "./AI"

const routes = [
  ...Reminders,
  ...AllOrks,
  ...MY_OKRS_ROUTERS,
  ...People,
  // ...Review,
  ...Profile,
  ...Login,
  ...Logout,
  ...GateWay,
  ...AI
]

export const modulesRoutes = _groupBy((({isPublic}) => isPublic ? "publicPages" : "privatePages"), routes)
