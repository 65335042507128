import React from "react"
import AntdTabs from "antd/es/tabs";
import "./Tabs.less";

const Tabs = props => <AntdTabs {...props} />;
Tabs.TabPane = AntdTabs.TabPane

const { TabPane: TabPaneAntd } = AntdTabs;
const TabPane = props => <TabPaneAntd {...props} />;

export { Tabs as default, TabPane };
