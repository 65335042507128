import ROUTES_MY_OKR from "modules/MyOkrs/Overview";
import {getPathFromRoutes} from "./settings";

export const BASE_URL = process && process.env.NODE_ENV === "development" ? "https://varoom-dev-admin-microservice.azurewebsites.net/api" : "https://api.varoomhr.com/api/";
export const CRM_VAROOM_APP_ID = process && process.env.NODE_ENV === "development" ? "62abe6b01b6ffe4bf8f5d926" : "62ff5e0fea0fdf2dc80b16ee";
export const SSO_HOSTING_URL = process && process.env.NODE_ENV === "development" ? "https://gpc-login-dev.azurewebsites.net" : "https://login.wowsuite.ai"
export const SSO_PROFILE_URL = `${SSO_HOSTING_URL}/profile?appid=${CRM_VAROOM_APP_ID}&callbackurl=${window.location.origin}/${getPathFromRoutes(...ROUTES_MY_OKR)}`
export const DFO_OAUTH_URL = `${SSO_HOSTING_URL}?appid=${CRM_VAROOM_APP_ID}`;
export const OAUTH_API_URL = process && process.env.NODE_ENV === "development"
  ? "https://oauth-dev-api.azurewebsites.net/api"
  : "https://oauth-prod-api.azurewebsites.net/api";

export const OAUTH_API_LOGOUT_URL = `${SSO_HOSTING_URL}/signout`
export const VAROOM_FAST_API_URL = process && process.env.NODE_ENV === "development" ? "https://vroom.firstcontactai.com" : "https://vroom.firstcontactai.com";