import {useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import {stateManager, actions} from "./store";

export const useUnreadMessageNumber = () => useSelector(stateManager.getUnreadMessageNumber);
export const useMessageList = () => useSelector(stateManager.getMessageList);
export const useInitData = () => {
  const dispatch = useDispatch();
  const isInit = useSelector(stateManager.isInitData);
  useEffect(() => {
    !isInit && dispatch(actions.initData())
  }, [dispatch, isInit])

  return !isInit
}
export const useMarkReadAction = () => {
  const dispatch = useDispatch();
  const markReadById = useCallback((id) => dispatch(actions.markReadById(id)), [dispatch]);
  const markAllRead = useCallback(() => dispatch(actions.markAllRead()), [dispatch])
  return [markReadById, markAllRead]
}