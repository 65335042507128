import React from 'react'
import classnames from "classnames"
import PropTypes from 'prop-types'
import Button from 'components/ui/Button'
import {Text} from 'components/ui/Typography'
import styles from './NotificationMessageListHeader.module.scss'

const NotificationMessageListHeader = ({onMarkAll}) => (
  <div className={styles.dfoComp}>
    <Text strong type="secondary" className={styles.dfoComp__label}>Notifications</Text>
    <Button className={classnames(styles.dfoComp__markReadBtn, "pr-2")} type="link" onClick={onMarkAll}>Mark all as read</Button>
  </div>
)

NotificationMessageListHeader.propTypes = {
  onMarkAll: PropTypes.func
}

export default NotificationMessageListHeader
