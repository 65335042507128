import React, {useState, useCallback} from "react";
import moment from "moment";
import _isEqual from "lodash/fp/isEqual"
import _capitalize from "lodash/fp/capitalize";
import _size from "lodash/fp/size"
import _filter from "lodash/fp/filter"
import _map from "lodash/fp/map"
import DatePicker from "components/ui/DatePicker";
import {Controller, useWatch} from "react-hook-form";
import Divider from "components/ui/Divider"
import Row from "components/ui/Row"
import Col from "components/ui/Col"
import Space from "components/ui/Space"
import Switch from "components/ui/Switch"
import Select, {Option} from "components/ui/Select"
import Form from "components/ui/Form"
import {Text} from "components/ui/Typography";
import TimePicker from "components/ui/TimePicker";
import AntdCheckbox from "components/ui/Checkbox";
import InputNumber from "components/ui/InputNumber";
import {getTask, getName, getWeekDay} from "./reminder.domainData";
import {FREQUENCY_TYPE, FREQUENCY_REPETITION, FORMAT_TIME, DEFAULT_WEEKDAY} from "./constants";
import {isCustomTime, weeklySelector} from "./reminder.utils"

const EmailReminder = (props) => {
  const {values, setValue, control} = props;
  const tasks = getTask(values);
  const weekDay = getWeekDay(values);
  const [repeat, setRepeat] = useState(weekDay)
  const formControl = useWatch();

  const handleCheckedAll = useCallback((visible) => {
    if(!visible) {
      setValue("tasks", _map((item) => ({...item, enabled: false}), tasks), { shouldDirty: true });
    } else {
      setValue("tasks", _map((item) => ({...item, enabled: true}), tasks), { shouldDirty: true });
    }
  }, [setValue, tasks]);

  const handleSetAllDate = useCallback((visible) => {
    if(!visible) {
      setValue("weekDay", [], { shouldDirty: true });
      setRepeat([])
    } else {
      setValue("weekDay", _map((item) => item.value, DEFAULT_WEEKDAY), { shouldDirty: true });
      setRepeat(_map((item) => item.value, DEFAULT_WEEKDAY))
    }
  }, [setValue, setRepeat])
  return (<>
    <Row gutter={8}>
      <Col span={12}>
        <Space className={"mb-10 w-full"}>
          <Switch checked={_size(_filter(({enabled: true}), tasks)) > 0} onChange={handleCheckedAll} /> <Text className="text-lg font-bold text-[#43474D]">Email Reminders</Text>
        </Space>
        {_size(tasks) > 0 && tasks.map((item, index) => {
          return <Space key={index} className={"mb-4 w-full"}>
            <Controller control={control} name={`tasks.${index}.enabled`} render={({field: {ref, value, ...refProp}}) => <Switch checked={value} {...refProp}/>} />
            <Text className="text-base text-[#848D99] font-medium">{getName(item)}</Text>
          </Space>
        })}
      </Col>
      <Col span={12}>
        <Controller
          name="frequencyHead"
          control={control}
          render={({field: {ref, onChange, ...refProp}}) => {
            const handleChange = (value) => {
              onChange(value);
              if(value !== "custom") {
                setValue("isCustom", false)
                setValue("weekDay", [])
              } else {
                setValue("isCustom", true)
              }
            }
            return <Select defaultValue="Weekly" onChange={handleChange} {...refProp} className="w-[170px]">
              {FREQUENCY_TYPE.map((item, index) => <Option key={index} value={item}>{_capitalize(item)}</Option>)}
            </Select>
          }}
        />
      </Col>
      <Form.Item
        label="Repeat At"
        className="py-7 px-5"
      >
        <Space>
          <Controller
            control={control}
            render={({field: {ref, value, ...refProp}}) => {
              return <DatePicker allowClear={false} format={"YYYY/MM/DD"} value={value ? moment(value): value}  {...refProp} />
            }}
            name="timeOfDay"
          />
          <Controller
            control={control}
            render={({field: {ref, value, ...refProp}}) => {
              return <TimePicker allowClear={false} showNow={false} use12Hours format={FORMAT_TIME} value={value ? moment(value) : value}  {...refProp} />
            }}
            name="timeOfDay"
          />
        </Space>
      </Form.Item>
      <Col span={24}>
        <div className={"bg-[#F5F7FA]"}>
          {isCustomTime(formControl) && <>
            <Form.Item
              label="Remind every"
              className="py-7 px-5 pb-0"
            >
              <Space>
                <Controller
                  control={control}
                  render={({field: {ref, ...refProp}}) => <InputNumber formatter={(value) => value ? value : 1} parser={(value) => value ? value : 1} defaultValue={2} min={1} {...refProp} className="w-[60px]"/>}
                  name="interval"
                />
                <Controller
                  render={({field: {ref, ...refProp}}) => <Select {...refProp} className="w-[100px]">
                    {FREQUENCY_REPETITION.map((item, index) => <Option key={index} value={item}>{_capitalize(item)}</Option>)}
                  </Select>}
                  name="frequencyEvery"
                />
              </Space>
            </Form.Item>
            <Divider className={"border-[#D5D7DB]"}/>
            {weeklySelector(formControl) && <>
              <Form.Item
                label="Repeat On"
                className="p-5"
              >
                <Controller
                  render={({field: {ref, value, onChange, ...refProp}}) => {
                    const handleOnChange = (values) => {
                      onChange(values);
                      setRepeat(values);
                    }
                    return <AntdCheckbox.Group className="cs-group-checkbox" value={value} onChange={handleOnChange} {...refProp} options={DEFAULT_WEEKDAY}/>
                  }}
                  name="weekDay"
                />
                <Space className={"mt-4 w-full"}><Switch checked={_isEqual(_size(repeat), _size(DEFAULT_WEEKDAY))} onChange={handleSetAllDate} /> <Text className="text-base text-[#43474D] font-medium">Set all days</Text></Space>
              </Form.Item>
            </>}
          </>}
        </div>
      </Col>
    </Row>
  </>)
}
EmailReminder.propTypes = {}
export default EmailReminder