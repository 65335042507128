import React, {useMemo} from "react";
import {Title, Text} from "components/ui/Typography";
import {getFullName, getFirstName, getLastName, getEmail, getPosition, getDepartment} from "domainData/user.domainData"
import styles from "./UserTitle.module.scss";

const UserTitle = ({user}) => {
  const nameDisplay = useMemo(() => getFullName(user) || getFirstName(user) + " " + getLastName(user) || getEmail(user) || "", [user]);
  return <div className={styles.userTitle}>
    <Title>{nameDisplay}</Title>
    <Text strong>{getPosition(user)}</Text>
    <Text align={"center"}>{getDepartment(user)}</Text>
  </div>
}
UserTitle.propTypes = {}
export default UserTitle