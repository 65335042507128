import React from 'react'
import { Row as AntdRow, Col as AntdCol } from "antd/es/grid";
import "./Grid.less";

const Row = (props) => <AntdRow {...props} />;
Row.propTypes = {
  ...AntdRow.propTypes
};
const Col = (props) => <AntdCol {...props} />;
Col.propTypes = {
  ...AntdCol.propTypes
};
export { Row, Col };
