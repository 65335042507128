import React, {useCallback} from "react";
import classnames from "classnames";
import Badge from "components/ui/Badge";
import {useUnreadMessageNumber, useInitData} from "components/shared/NotificationList";
import {BellOutlined, SyncOutlined} from "@ant-design/icons"
import styles from "./Notification.module.scss";

const Notification = ({showListNotify}) => {
  const isLoading = useInitData();
  const unReadMessages = useUnreadMessageNumber();
  const handleClick = useCallback(() => {
    if (showListNotify) {
      showListNotify();
    }
  }, [showListNotify]);
  return <div className={styles.dfoComp}>
    {isLoading ? (
      <SyncOutlined spin/>
    ) : (
      <Badge count={unReadMessages}>
        <BellOutlined onClick={handleClick} className={classnames(styles.dfoComp_icon, "ant-anticon-bell")}/>
      </Badge>
    )}
  </div>
}

Notification.propTypes = {}
export default Notification