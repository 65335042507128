import React from 'react'
import PropTypes from 'prop-types'
import AntdButton from 'antd/es/button'
import classNames from 'classnames'
import Tooltip from './../Tooltip'
import './Button.less'


const Button = ({className, ctrTooltip, ...props}) => {
  const btn = <AntdButton
    {...props}
    className={classNames({'btn-dfoSecondary': props.type === 'secondary'}, className)}
  />

  if (ctrTooltip) {
    return <Tooltip title={ctrTooltip}>
      {btn}
    </Tooltip>
  }

  return btn
}

Button.defaultProps = {
  className: ''
}

Button.propTypes = {
  className: PropTypes.string,
  type: PropTypes.string
}

Button.Group = AntdButton.Group

export default Button
