import React, {useMemo} from 'react'
import AntdList from 'antd/es/list'
import {useTranslateContext} from 'utils/varoom-translation.utils'
import messages from './defined.messages'
import './List.less'

// eslint-disable-next-line react/prop-types
const List = ({pagination, dataSource, ...props}) => {
  const {formatMessage} = useTranslateContext()
  const paginationConfig = useMemo(
    () =>
      pagination
        ? {
          showSizeChanger: true,
          className: 'cxw-block',
          ...(!pagination.showTotal && {
            showTotal: (total, [from, to]) => formatMessage(messages.paginationFromTo,{from, to, total})
          }),
          ...(!pagination.onShowSizeChange && pagination.onChange ? {onShowSizeChange: (_, size) => pagination.onChange(1, size)} : {}),
          ...pagination
        }
        : pagination,
    [formatMessage, pagination]
  )

  return <AntdList
    {...props}
    pagination={!dataSource || !dataSource.length ? false : paginationConfig}
    dataSource={dataSource}
  />
}
export {default as Item} from './Item'
export {default as Meta} from './Item/Meta'
export default List
