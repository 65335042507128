import { eventChannel } from 'redux-saga'

export default function createPublishmentChannel(notiConnection ) {
  return eventChannel(emit => {

    const onNewNoti = (event) => {
      // puts event payload into the channel
      emit(event)
    }

    // setup the subscription
    notiConnection.on('reminderNotification', onNewNoti)


    const unsubscribe = () => {
      notiConnection.off('reminderNotification', onNewNoti)
    }

    return unsubscribe
  })
}
