import React from "react"

class ErrorBoundaryLayout extends React.Component {
  componentDidCatch(error, errorInfo) {
    console.log('lazy load component fail')
    console.error(error)
    console.error(errorInfo)
    const errorString = error.toString()
    console.log(errorString)
    // DEV-NOTES: lazy-load components get undefined sometimes (related to bundling & code splitting - unknown root cause)
    // temporary solution: force reload page, will remove this when we update to webpack@4.x
    if (errorString.indexOf('Element type is invalid: expected a string ') >= 0 || errorString.indexOf('Loading chunk') >= 0) {
      // setTimeout(() => window.location.reload(), 1000)
    }
  }

  // eslint-disable-next-line react/prop-types
  render = () => this.props.children
}

ErrorBoundaryLayout.propTypes = {}

export default ErrorBoundaryLayout



