import React from "react";
import {FormattedMessage} from "react-intl";
import messages from "./defined.messages";
import {FEATURE_KEY as FEATURE_KEY__ALL_OKRS} from "modules/AllOkrs"
import {FEATURE_KEY as FEATURE_KEY__MY_OKRS} from "modules/MyOkrs"
import {FEATURE_KEY as FEATURE_KEY__PEOPLE} from "modules/People"
// import {FEATURE_KEY as FEATURE_KEY__REVIEW} from "modules/Review"
import {FEATURE_KEY as FEATURE_KEY__PROFILE} from "modules/Profile"
import {FEATURE_KEY as FEATURE_KEY__REMINDERS} from "modules/Reminders"
import { FEATURE_KEY as FEATURE_KEY__AI } from "modules/AI"

export const SIDEBAR_ITEMS = [
  {
    labelId: 'app.mainMenu.ai',
    path: '/varoomai',
    featureKey: FEATURE_KEY__AI
  },
  {
    labelId: 'app.mainMenu.allOkrs',
    path: '/all-okrs',
    featureKey: FEATURE_KEY__ALL_OKRS
  },
  {
    labelId: 'app.mainMenu.myOkrs',
    path: '/my-okrs',
    featureKey: FEATURE_KEY__MY_OKRS
  },
  {
    labelId: 'app.mainMenu.people',
    path: '/people',
    featureKey: FEATURE_KEY__PEOPLE
  },
//  {
//     labelId: 'app.mainMenu.review',
//     path: '/review',
//     featureKey: FEATURE_KEY__REVIEW
//   },
  {
    labelId: 'app.mainMenu.profile',
    path: '/profile',
    featureKey: FEATURE_KEY__PROFILE
  },
  {
    labelId: 'app.mainMenu.reminders',
    path: '/reminders',
    featureKey: FEATURE_KEY__REMINDERS
  }
]

export const SIDEBAR_PROFILE = [
  {
    label: <FormattedMessage {...messages.viewProfile} />,
    noSelected: true,
    url: "/profile"
  }
]

export const SIDEBAR_ACCOUNT_GREY = [
  {
    label: <FormattedMessage {...messages.signOut} />,
    path: '/logout'
  }
]