import {defineMessages} from 'utils/varoom-translation.utils'

export default defineMessages({
  today: {
    id: 'app.common.datepicker.today',
    defaultMessage: "Today"
  },
  lastTimeDay: {
    id: 'app.common.datepicker.lastTimeDay',
    defaultMessage: "Last {day} days"
  },
})
