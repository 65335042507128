import React from "react"
import AppShellLayout from "components/shared/AppShell"

const PrivatePage = ({children}) => (
  <AppShellLayout>
    {children}
  </AppShellLayout>
)

PrivatePage.propTypes = {}

export default PrivatePage



