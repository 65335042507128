import {BASE_URL} from "utils/ssoSetting"
import privateFetcher from "./privateFetcher";

export const getNotificationListFetcher = (params) => privateFetcher.get(`${BASE_URL}/notifications`, params).then(({data}) => ({...data}))
export const setNotificationMaskAsReadFetcher = (id) => privateFetcher.post(`${BASE_URL}/notifications/${id}/read`)
export const getUserList = (params)  => privateFetcher.get(`${BASE_URL}/users`, params).then(({data, ...response}) => ({...response, results: data || []}))
export const getUserProfile = ()  => privateFetcher.get(`${BASE_URL}/profile`, null).then(({data, ...response}) => ({...response, results: data || []}))
export const getUserDetails = ({id})  => privateFetcher.get(`${BASE_URL}/users/${id}`, null).then(({data, ...response}) => ({...response, results: data || []}))
export const createUser = ({formData}) => privateFetcher.post(`${BASE_URL}/users`, null, formData).then(({data, ...response}) => ({...response, results: data || []}))
export const updateUser = ({formData}) => privateFetcher.put(`${BASE_URL}/users/${formData.id}`, null, formData).then(({data, ...response}) => ({...response, results: data || []}))
export const updateProfile = ({formData}) => privateFetcher.put(`${BASE_URL}/profile`, null, formData).then(({data, ...response}) => ({...response, results: data || []}))
export const deleteUser = ({id}) => privateFetcher.delete(`${BASE_URL}/users/${id}`, null)