import _debounce from 'lodash/fp/debounce'
import _pipe from 'lodash/fp/pipe'
import _map from 'lodash/fp/map'
import _compact from 'lodash/fp/compact'
import _isNil from 'lodash/fp/isNil'
import _take from 'lodash/fp/take'
import React, {useState, useEffect, useCallback, useMemo} from 'react'
import Spin from '../Spin'
import PropTypes from 'prop-types'
import _get from 'lodash/fp/get'
import _omit from 'lodash/fp/omit'
import _some from 'lodash/fp/some'
import Select from './Select'
import AntdSelect from 'antd/es/select'
import {FormattedMessage} from 'react-intl'
import {isExcludedMe, getItemTitle, getItemValue} from './helpers'


const fnFilterOptionByContent = (input = '', {key = '', props: {children = ''}}) =>
  input && input.length > 1
    ? _some(str => str.includes(input.toLowerCase()), [children.toLowerCase(), key.toLowerCase()])
    : true

const renderItemsDefault = (dataSource, props) => _pipe([
  _map(item => {
    const key = getItemValue(item, props)
    return isExcludedMe(key, props)
      ? null
      : (<AntdSelect.Option key={key} value={key} item={item}>{getItemTitle(item, props) || key}</AntdSelect.Option>)
  }),
  _compact])(dataSource)

const SelectWithDataSourceUi = ({
  dataSource = [],
  isLoading,
  isLoadError,
  disabled,
  placeholderId,
  placeholder,
  renderItems,
  getLazyDataSource,
  onChange,
  useDefaultFilterOption,
  filterOption = false,
  ...props
}) => {

  const onDropdownVisibleChange = useCallback((isOpen) => isOpen && getLazyDataSource && getLazyDataSource(), [getLazyDataSource])
  const onChangeCb = useCallback((selectedValue, selectedComp) => onChange(selectedValue, _get('props.item', selectedComp), selectedComp)
    , [onChange])

  return (
    <Select
      {..._omit(['getItemValue', 'itemTitleKey', 'itemValueKey', 'getItemTitle'], props)}
      onChange={onChangeCb}
      autoClearSearchValue={false}
      filterOption={useDefaultFilterOption ? fnFilterOptionByContent : filterOption}
      onDropdownVisibleChange={onDropdownVisibleChange}
      disabled={isLoading || isLoadError ? true : disabled}
      placeholder={placeholderId ? <FormattedMessage id={placeholderId}/> : placeholder}>
      {renderItems ? renderItems(dataSource) : renderItemsDefault(dataSource, props)}
    </Select>
  )
}

SelectWithDataSourceUi.propTypes = {
  dataSource: PropTypes.array,
  getLazyDataSource: PropTypes.any,
  disabled: PropTypes.any,
  isLoading: PropTypes.bool,
  isLoadError: PropTypes.bool,
  getItemTitle: PropTypes.func,
  getItemValue: PropTypes.func,
  renderItems: PropTypes.func,
  itemValueKey: PropTypes.string,
  itemTitleKey: PropTypes.string,
  placeholderId: PropTypes.string,
  placeholder: PropTypes.any,
  excludedItems: PropTypes.any,
  onChange: PropTypes.func.isRequired
}

const SelectWithDataSource = ({
  reInit,
  dataSource,
  getDataSource,
  getDataSourceBySearchKey,
  getInitDataSource,
  defaultValue,
  ...props
}) => {

  const [internalInitDataSource, set_internalInitDataSource] = useState(dataSource || [])
  const [internalLazyDataSource, set_internalLazyDataSource] = useState([])
  const [internalLazyDataSourceProcess, set_internalLazyDataSourceProcess] = useState()


  const notFoundContent = useMemo(() => {
    console.log(internalLazyDataSourceProcess)
    return _get('isLoading', internalLazyDataSourceProcess)
      ? <Spin size="small"/> : _get('error', internalLazyDataSourceProcess)
        ? 'Error. Cannot load source' : undefined
  }, [internalLazyDataSourceProcess])

  const geDataSourceWrapper = useCallback(async (fnGetSource, ...args) => {
    let isUnmounted = false
    try {

      set_internalLazyDataSource([])
      set_internalLazyDataSourceProcess({isLoading: true})

      const dataSource = await fnGetSource(...args)
      if (!isUnmounted) {
        set_internalLazyDataSource(_take(100, dataSource || []))
        set_internalLazyDataSourceProcess(undefined)
      }
    } catch (error) {
      !isUnmounted && set_internalLazyDataSourceProcess(error)
    }

    return () => {
      isUnmounted = true
    }

  }, [])

  const onSearch = useCallback(_debounce(1000, searchString => geDataSourceWrapper(getDataSourceBySearchKey, (searchString || '').trim())
  ), [geDataSourceWrapper, getDataSourceBySearchKey])

  const getLazyDataSource = useCallback(() => {
    const getSource = getDataSourceBySearchKey || getDataSource
    getSource && geDataSourceWrapper(getSource)
  }, [geDataSourceWrapper, getDataSourceBySearchKey, getDataSource])

  useEffect(() => {
    set_internalLazyDataSource(null)
  }, [getDataSourceBySearchKey, getDataSource, reInit])

  //control mount/unmount state for async process
  useEffect(() => {
    if (getInitDataSource) {
      getInitDataSource().then(set_internalInitDataSource)
    } else if (defaultValue) {
      getLazyDataSource()
    }
  }, [defaultValue, getInitDataSource, getLazyDataSource])

  return (
    <SelectWithDataSourceUi
      {...props}
      defaultValue={defaultValue}
      dataSource={_isNil(internalLazyDataSource) ? internalInitDataSource : internalLazyDataSource}
      getLazyDataSource={_isNil(internalLazyDataSource) ? getLazyDataSource : undefined}
      notFoundContent={notFoundContent}
      showSearch={true}
      onSearch={getDataSourceBySearchKey ? onSearch : null}
    />
  )
}

SelectWithDataSource.propTypes = {
  dataSource: PropTypes.any,
  getDataSource: PropTypes.func,
  getDataSourceBySearchKey: PropTypes.func,
  value: PropTypes.any,
  onChange: PropTypes.func,
  getInitDataSource: PropTypes.func,
  renderItems: PropTypes.func,
  useDefaultFilterOption: PropTypes.bool,
  filterOption: PropTypes.any,
  getInitItems: PropTypes.any,
  reInit: PropTypes.any
}

SelectWithDataSource.defaultProps = {
  style: {width: '100%'},
  autoClearSearchValue: true
}

export default SelectWithDataSource
