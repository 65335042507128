import React from "react"
import AntdMenu from 'antd/es/menu'
import './Menu.less'


const Menu = props => <AntdMenu {...props} />

const {Item: ItemAndtd} = AntdMenu
Menu.Item = function Item(props) {
  return <ItemAndtd {...props} />
}

const {ItemGroup: ItemGroupAndtd} = AntdMenu
Menu.ItemGroup = function ItemGroup(props) {
  return <ItemGroupAndtd {...props} />
}

const {SubMenu: SubMenuAndtd} = AntdMenu
Menu.SubMenu = function SubMenu(props) {
  return <SubMenuAndtd {...props} />
}

const {Divider: DividerAndtd} = AntdMenu
Menu.Divider = function Divider(props) {
  return <DividerAndtd {...props} />
}

export default Menu
