export const defaultValues = {
  id: "",
  frequency: "",
  interval: 1,
  timeOfDay: new Date(),
  weekDay: [],
  isCustom: false,
  tasks: [
    {
      name: "Objective & Key Results",
      enabled: false,
      type: 1
    },
    {
      name: "Inspiration",
      enabled: false,
      type: 2
    },
    {
      name: "To-do List Items",
      enabled: false,
      type: 3
    }
  ]
}