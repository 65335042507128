import React from "react"
import PropTypes from "prop-types"
import Skeleton from "./Skeleton"
import {Row, Col} from "./../Grid"


const COL_CONFIG = {
  GRID: {
    xs: 24,
    sm: 12,
    md:6
  },
  LIST: {
    span: 24
  }
}

const SkeletonList = ({numItem = 4, isGrid, ...rest}) => (
  <Row gutter={16}>
    {(Array(numItem).fill(0)).map(
      (_, idx) => <Col key={idx} {...COL_CONFIG[isGrid ? "GRID" : "LIST"]}><Skeleton active paragraph round {...rest}/></Col>)}
  </Row>)

SkeletonList.propTypes = {
  avatar: PropTypes.bool
}

export default SkeletonList








