import {MODULE_STATE_NAME} from "./constants"

// Actions
export const INIT_DATA = `${MODULE_STATE_NAME}/INIT-DATA`
export const RESET_DATA = `${MODULE_STATE_NAME}/RESET-DATA`
export const GET_REMINDER = `${MODULE_STATE_NAME}/GET-REMINDER`;
export const GET_REMINDER_SUCCESS = `${MODULE_STATE_NAME}/GET-REMINDER-SUCCESS`;
export const GET_REMINDER_ERROR = `${MODULE_STATE_NAME}/GET-REMINDER-ERROR`;
export const UPDATE_REMINDER = `${MODULE_STATE_NAME}/UPDATE-REMINDER`;
export const UPDATE_REMINDER_SUCCESS = `${MODULE_STATE_NAME}/UPDATE-REMINDER-SUCCESS`;
export const UPDATE_REMINDER_ERROR = `${MODULE_STATE_NAME}/UPDATE-REMINDER-ERROR`;