export const FEATURE_KEY = "features.reminder"
export const REPEAT_ON = ["Mo", "Tu", "We", "Th", "Fr", "Sa"];
export const FREQUENCY_TYPE = ["once", "daily", "weekly", "monthly", "annually", "custom"]
export const FREQUENCY_REPETITION = ["daily", "weekly", "monthly", "annually"];
export const DEFAULT_WEEKDAY = [
  {
    label: "Mo",
    value: "monday"
  },
  {
    label: "Tu",
    value: "tuesday"
  },
  {
    label: "We",
    value: "wednesday"
  },
  {
    label: "Th",
    value: "thursday"
  },
  {
    label: "Fr",
    value: "friday"
  },
  {
    label: "Sa",
    value: "saturday"
  },
  {
    label: "Su",
    value: "sunday"
  }];
export const FORMAT_TIME = "hh a";
