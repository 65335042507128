import LogoutLayout from './LogoutLayout.Loadable'

const ROUTES = [{
  path: '/logout',
  exact: true,
  component: LogoutLayout,
  isPublic: true
}];

export default ROUTES
