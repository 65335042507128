import { toast } from "react-toastify";

function displayToastMessage (notify, message) {
  if (!toast.isActive(message)) {
    const option =  {
      toastId: message,
      pauseOnFocusLoss: false
    };
    switch (notify) {
      case 'error':
        // eslint-disable-next-line no-use-before-define
        toast.error(message, {autoClose: message && getAutoCloseTime(message.length), ...option});
        break;
      case 'warning':
        // eslint-disable-next-line no-use-before-define
        toast.warn(message, {autoClose: message && getAutoCloseTime(message.length), ...option});
        break;
      case 'success':
        toast.success(message, option);
        break;
      default:
        toast.info(message, option);
        break;
    }
  }

  function getAutoCloseTime(messageLength) {
    return messageLength < 50 ? 15*1000
      : messageLength < 100 ? 30*1000
        : messageLength < 150 ? 40*1000
          : false
  }
}

export function showToastMessage(notify, messages) {
  if ( notify === 'error' && messages && messages.errors) {
    messages = messages.errors
  }

  if (messages && messages.message) {
    messages = messages.message
  }

  const type = typeof messages;
  switch(type) {
    case 'object':
      if (messages && messages.forEach) {
        messages.forEach(msg => {
          displayToastMessage(notify, msg.message || msg.errorMessage);
        });
      } else {
        displayToastMessage(notify, "Something went wrong, please contact support@ctrwow.com for further assist.");
      }
      break;
    case 'string':
    default:
      displayToastMessage(notify, messages);
      break;
  }
}
