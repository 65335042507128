import AILayoutLoadable from "./AILayout.Loadable";
import { FEATURE_KEY } from "./constants";

const ROUTES = [
    {
        path: `/varoomai`,
        exact: true,
        component: AILayoutLoadable,
        layoutConfig: {
            isUsedAppShell: true,
            title: "Varoom A.I",
            routes: [
                {
                    breadcrumbName: "Varoom A.I"
                }
            ]
        },
        featureKey: FEATURE_KEY
    }
]
export default ROUTES;