export const RESTART_ON_REMOUNT = "@@saga-injector/restart-on-remount";
export const DAEMON = "@@saga-injector/daemon";
export const ONCE_TILL_UNMOUNT = "@@saga-injector/once-till-unmount";
export const CDN_CTRWOW_COMMON_LIB__BASE_URL = "https://ctrwow-commonstorage.azureedge.net"
export const EDITOR_JS_LINK = `${CDN_CTRWOW_COMMON_LIB__BASE_URL}/public-assets/ctrwow_web_funnel/tinymce-5.5.1${process && process.env.NODE_ENV === "development" ? "" : ""}/tinymce.min.js`;


export const VAR_CURRENT_USER = "varCurrentUser"
export const AUTH = "auth"

export const DATE_OPTIONS_CURRENT_QUARTER = "Current Quarter";
export const DATE_OPTIONS_PREVIOUS_QUARTER = "Previous Quarter";
export const DATE_OPTIONS_THIS_MONTH = "This month";
export const DATE_OPTIONS_LAST_MONTH = "Last month";
export const DATE_OPTIONS_THIS_YEAR = "This year";
export const REPORT_KEY = "Report";

export const PERMISSION_FEATURE = {
  GET: 1,
  CREATE: 2,
  UPDATE: 3,
  DELETE: 4,
}