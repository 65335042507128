import React from 'react'
import PropTypes from 'prop-types'
import AntdInputNumber from 'antd/es/input-number'
import './InputNumber.less'


const DEFAULT_NUMBER_FORMAT = {
  currency: {
    formatter: value => `$ ${value}`.replace(/\B(?=(\d{3})+(?!\d))/g, ','),
    parser: value => value.replace(/\$\s?|(,*)/g, '')
  }
}

const InputNumber = ({ctrFormatType, ...props}) => <AntdInputNumber {...(DEFAULT_NUMBER_FORMAT[ctrFormatType] || {})}  {...props} />

InputNumber.propTypes = {
  ...AntdInputNumber.propTypes,
  ctrFormatType: PropTypes.any
}

export default InputNumber
