import React from "react"
import classname from 'classnames'
import Logo from "components/shared/Logo";
import {Sider} from "components/ui/Layout"
import UserProfile from "components/shared/UserProfile"
import MainMenu from "../components/MainMenu";
import styles from "./LeftSide.module.scss"
import appStyles from "app-variables.scss";

const LeftSide = () => {
  return (
    <Sider
      className={classname(styles.varComp)}
      width={appStyles.ctrLayoutSidebarWidth}
    >
      <Logo className={"dfoMar__base--b logo"}/>
      <UserProfile/>
      <MainMenu />
    </Sider>
  )
}

LeftSide.propTypes = {}
export default LeftSide



