import React,{Fragment} from 'react'
import PropTypes from 'prop-types'
import Tooltip from './../Tooltip'
import {QuestionCircleTwoTone} from '@ant-design/icons';
import {FormattedMessage} from 'react-intl'

// eslint-disable-next-line no-unused-vars,react/prop-types
const LabelWithHelp = ({label, labelId, description}) => (
  <Fragment>
    {labelId ? <FormattedMessage id={labelId}/> : label || labelId}
    <Tooltip title={description}>
      <QuestionCircleTwoTone style={{paddingLeft: '5px', position: 'relative', top: '-1px'}}/>
    </Tooltip>
  </Fragment>
)

LabelWithHelp.propTypes = {
  label: PropTypes.string,
  labelId: PropTypes.string,
  description: PropTypes.string
}

export default LabelWithHelp



